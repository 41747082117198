import React, { FC, ReactNode, useEffect, useRef } from 'react';
import {
  UIStore,
  SearchStore,
  IClasses,
  IAPIGridRequest,
  GridPagination,
  Utilities,
  GRID_ACTIONS,
} from '@wings-shared/core';
import {
  ColDef,
  ColGroupDef,
  GridOptions,
  ICellEditorParams,
  RowEditingStartedEvent,
  RowNode,
} from 'ag-grid-community';
import { inject, observer } from 'mobx-react';
import { finalize, takeUntil } from 'rxjs/operators';
import { SidebarStore } from '@wings-shared/layout';
import { ISearchHeaderRef, SearchHeaderV2, SearchHeaderV3, useSearchHeader } from '@wings-shared/form-controls';
import {
  AgGridActions,
  AgGridAutoComplete,
  AgGridCellEditor,
  AgGridGroupHeader,
  AgGridSelectControl,
  AgGridViewRenderer,
  CustomAgGridReact,
  agGridUtilities,
  useAgGrid,
  useGridFilters,
  useGridState,
} from '@wings-shared/custom-ag-grid';
import { useUnsubscribe } from '@wings-shared/hooks';
import { useLocation } from 'react-router';
import { LocationOnBoardingApprovalStore, SettingsStore, VendorManagementStore } from '../../Stores';
import { LOCATION_ONBOARDING_APPROVAL_FILTERS } from '../Shared/Enums/LocationOnBoardingApprovals.enum';
import { LocationOnBoardingApprovalsModel } from '../Shared/Models/LocationOnBoardingApprovals.model';
import { sidebarMenus } from '../Shared/Components/SidebarMenu/SidebarMenu';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { Tooltip, withStyles } from '@material-ui/core';
import { CancelOutlined, RemoveRedEyeOutlined } from '@material-ui/icons';
import { styles } from './LocationOnBoardingApprovals.styles';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import { gridFilters } from './fields';

interface Props {
  sidebarStore?: typeof SidebarStore;
  locationOnBoardingApprovalStore?: LocationOnBoardingApprovalStore;
  settingsStore?: SettingsStore;
  classes?: IClasses;
}

const LocationOnBoardingApprovals: FC<Props> = ({ locationOnBoardingApprovalStore,settingsStore, classes }) => {
  const unsubscribe = useUnsubscribe();
  const gridState = useGridState();
  const location = useLocation();
  const searchHeaderRef = useRef<ISearchHeaderRef>();
  const agGrid = useAgGrid<LOCATION_ONBOARDING_APPROVAL_FILTERS, LocationOnBoardingApprovalsModel>(
    gridFilters,
    gridState
  );
  const filtersApi = useGridFilters<LOCATION_ONBOARDING_APPROVAL_FILTERS>(gridFilters, gridState);
  const searchHeader = useSearchHeader();
  const isStatusFilter = Utilities.isEqual(
    searchHeaderRef.current?.getSelectedOption('defaultOption'),
    LOCATION_ONBOARDING_APPROVAL_FILTERS.VENDOR_NAME
  );

  useEffect(() => {
    SidebarStore.setNavLinks(sidebarMenus, 'vendor-management');
    const searchData = SearchStore.searchData.get(location.pathname);
    if (searchData) {
      gridState.setPagination(searchData.pagination);
      searchHeaderRef.current?.setupDefaultFilters(searchData);
      SearchStore.clearSearchData(location.pathname);
      return;
    }
    loadInitialData();
  }, []);

  const searchCollection = (): IAPIGridRequest | null => {
    const propertyValue = getSearchValue();
    if (propertyValue === '') {
      return null;
    }
    const property = gridFilters.find(({ uiFilterType }) =>
      Utilities.isEqual(uiFilterType, searchHeaderRef.current.selectedOption)
    );
    const filters = [
      {
        propertyName: property?.apiPropertyName,
        propertyValue: propertyValue,
        operator: 'string',
        filterType: 'string',
      },
    ];
    return {
      filterCollection: JSON.stringify(filters),
    };
  };

  const getSearchValue = (): string => {
    const searchHeader = searchHeaderRef.current;
    const chip = searchHeader?.getFilters().chipValue?.valueOf();
    if (!searchHeader) {
      return null;
    }

    const propertyValue = chip?.length > 0 ? chip[0]?.label : searchHeader.searchValue ? searchHeader.searchValue : '';
    return propertyValue;
  };

  const loadInitialData = (pageRequest?: IAPIGridRequest): void => {
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      ...pageRequest,
      ...searchCollection(),
      ...agGrid.filtersApi.gridSortFilters(),
    };
    UIStore.setPageLoader(true);
    locationOnBoardingApprovalStore
      .getLocationOnboardApprovalList(request)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(Response => {
        gridState.setGridData(Response.results);
        gridState.setPagination(new GridPagination({ ...Response }));
        agGrid.reloadColumnState();
        agGrid.refreshSelectionState();
      });
  };
  const columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'Vendor Name',
      field: 'vendor.name',
      editable: false,
      headerTooltip: 'Vendor Name',
    },
    {
      headerName: 'Vendor Code',
      field: 'vendor.code',
      editable: false,
      headerTooltip: 'Vendor Code',
    },
    {
      headerName: 'Location Name',
      field: 'locationName',
      editable: false,
      headerTooltip: 'Location Name',
    },
    {
      headerName: 'Airport',
      field: 'airportReference.label',
      editable: false,
      headerTooltip: 'Airport',
    },
    ...agGrid.auditFields(gridState.isRowEditing),
    {
      headerName: 'Actions',
      field: 'actionRenderer',
      cellRenderer: 'actionViewRenderer',
      cellEditor: 'actionRenderer',
      maxWidth: 250,
      minWidth: 250,
      sortable: false,
      filter: false,
      suppressSizeToFit: true,
      suppressNavigable: true,
    },
  ];

  const gridOptions = (): GridOptions => {
    const baseOptions: Partial<GridOptions> = agGrid.gridOptionsBase({
      context: {
        onDropDownChange: (params: ICellEditorParams, value: string) => {
          gridState.setHasError(Utilities.hasInvalidRowData(gridState.gridApi));
        },
        onInputChange: (params: ICellEditorParams, value: string) => {
          gridState.setHasError(Utilities.hasInvalidRowData(gridState.gridApi));
        },
        onOptionChange: (params: ICellEditorParams, value: string) => {
          gridState.setHasError(Utilities.hasInvalidRowData(gridState.gridApi));
        },
      },
      columnDefs: columnDefs,
      isEditable: true,
      gridActionProps: {
        getDisabledState: () => gridState.hasError,
        getEditableState: () => gridState.isRowEditing,
        getViewRenderer: (rowIndex: number, { data }: RowNode) => {
          return (
            <>
              <PrimaryButton variant="outlined" color="primary">
                <Tooltip title="Details">
                  <RemoveRedEyeOutlined className={classes.icons} />
                </Tooltip>
              </PrimaryButton>
              <PrimaryButton variant="outlined" color="primary">
                <Tooltip title="Approve">
                  <CheckOutlinedIcon className={classes.icons} />
                </Tooltip>
              </PrimaryButton>

              <PrimaryButton variant="outlined" color="primary">
                <Tooltip title="Reject">
                  <CancelOutlined className={classes.icons} />
                </Tooltip>
              </PrimaryButton>
            </>
          );
        },

        onAction: (action: GRID_ACTIONS, rowIndex: number) => {
          if (action === GRID_ACTIONS.SAVE) {
            gridState.gridApi.stopEditing();
            return;
          }
          gridState.gridApi.stopEditing(true);
        },
      },
    });

    return {
      ...baseOptions,
      pagination: false,

      suppressClickEdit: true,
      suppressRowClickSelection: true,
      suppressCellSelection: true,
      suppressScrollOnNewData: true,
      rowHeight: 40,
      defaultColDef: {
        ...baseOptions.defaultColDef,
        suppressMovable: true,
        sortable: false,
        cellEditor: '',
      },
      frameworkComponents: {
        customHeader: AgGridGroupHeader,
        customAutoComplete: AgGridAutoComplete,
        actionViewRenderer: AgGridViewRenderer,
        viewRenderer: AgGridViewRenderer,
        actionRenderer: AgGridActions,
        customCellEditor: AgGridCellEditor,
        customSelect: AgGridSelectControl,
      },
      onFilterChanged: () => loadInitialData({ pageNumber: 1 }),
      onSortChanged: e => {
        agGrid.filtersApi.onSortChanged(e);
        loadInitialData();
      },
    };
  };
  return (
    <>
      <SearchHeaderV2
        placeHolder="Start typing to search"
        ref={searchHeaderRef}
        onExpandCollapse={agGrid.autoSizeColumns}
        selectInputs={[
          agGridUtilities.createSelectOption(
            LOCATION_ONBOARDING_APPROVAL_FILTERS,
            LOCATION_ONBOARDING_APPROVAL_FILTERS.VENDOR_NAME,
            'defaultOption'
          ),
        ]}
        onClear={()=>{
          loadInitialData();
        }}
        onResetFilterClick={() => {
          agGrid.cancelEditing(0);
          agGrid.filtersApi.resetColumnFilters();
        }}
        onFilterChange={isInitEvent => {
          loadInitialData({ pageNumber: isInitEvent ? gridState.pagination.pageNumber : 1 });
          agGrid.cancelEditing(0);
        }}
      />
      
      <CustomAgGridReact
        isRowEditing={gridState.isRowEditing}
        rowData={gridState.data}
        gridOptions={gridOptions()}
        serverPagination={true}
        paginationData={gridState.pagination}
        onPaginationChange={loadInitialData}
        classes={{ customHeight: classes.customHeight }}
        disablePagination={gridState.isRowEditing || gridState.isProcessing}
      />
    </>
  );
};

export default inject(
  'locationOnBoardingApprovalStore',
  'settingsStore'
)(withStyles(styles)(observer(LocationOnBoardingApprovals)));
